import React, { useContext } from 'react'
import classnames from 'classnames'

import Header from '~/components/Header'
import Footer from '~/components/Footer'
import { StoreContext } from '~/context/StoreContext'

import * as classes from './layout.module.scss'

const Layout = ({ isHomePage, children }) => {
  const { isMenuOpen } = useContext(StoreContext)

  return (
    <div
      className={classnames('global-wrapper', {
        [classes.isMenuOpen]: isMenuOpen,
      })}
      data-is-root-path={isHomePage}
    >
      <Header isHomePage />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
