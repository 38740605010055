import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import Container from '~/components/Container'
import Logo from '~/components/Logo'
import SocialLink from '~/components/SocialLink'

import FooterBg from '~/images/footer-bg.svg'

import * as classes from './Footer.module.scss'

const Footer = () => {
  const {
    prismicFooterNavigation: {
      data: { links: menuItems },
    },
  } = useStaticQuery(graphql`
    {
      prismicFooterNavigation {
        data {
          links {
            link {
              uid
              isBroken
              target
            }
            link_title {
              text
            }
          }
        }
      }
    }
  `)

  return (
    <footer className={classes.footer}>
      <div className={classes.bgImage}>
        <img className={classes.imgCover} src={FooterBg} alt="BG" />
      </div>
      <Container className={classes.containerFooter}>
        <Logo isWhite={true} />
        <div className={classes.footerMenu}>
          <h3>Info</h3>
          {menuItems && (
            <ul className={classes.listMenu}>
              {menuItems.map((item, index) => (
                <li key={`${item['link_title'].text}-${item.link.uid}`}>
                  <Link to={`/${item.link.uid}`} target={item.link.target}>
                    {item['link_title'].text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className={classes.leftBlock}>
          <h3>Contact</h3>
          <a href="mailto:hello@southpawstudio.co.nz">
            hello@southpawstudio.co.nz
          </a>
          <a href="tel:0211824054">021 182 4054</a>
        </div>
        <div className={classes.rightBlock}>
          <h3>STAY CONNECTED</h3>
          <div>
            <SocialLink
              isWhite={true}
              addInstaTitle={true}
              className={classes.socialLinkFooter}
            />
          </div>
        </div>
      </Container>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
