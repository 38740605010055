import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import classenames from 'classnames'
import { Pivot as Hamburger } from 'hamburger-react'

import { StoreContext } from '~/context/StoreContext'

import * as classes from './MenuMobile.module.scss'

const Menu = ({ items }) => {
  const { isMenuOpen, toogleIsMenuOpen } = useContext(StoreContext)

  const goTo = link => {
    navigate(link)
    toogleIsMenuOpen()
  }

  return (
    <>
      <Hamburger
        className={classes.burgerMenu}
        toggled={isMenuOpen}
        toggle={toogleIsMenuOpen}
      />
      <div
        className={classenames(classes.mobileMenu, {
          [classes.isOpen]: isMenuOpen,
        })}
      >
        <nav className={classes.navigation}>
          {items.map((item, index) => (
            <span
              onClick={() => goTo(`/${item.link?.uid && item.link.uid}`)}
              key={item.link_label.text}
              tabIndex={index}
              role={'link'}
            >
              {item.link_label.text}
            </span>
          ))}
        </nav>
      </div>
    </>
  )
}

export default Menu
