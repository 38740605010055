import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FacebookLogo from './FacebookLogo'
import InstagramLogo from './InstagramLogo'

import * as classes from './socialLink.module.scss'

const colors = {
  default: '#ED9F18',
  white: '#FFFFFF',
  blue: '#00295B',
}

const SocialLink = ({ color, className, addInstaTitle }) => {
  const colorSet = color ? colors[color] : colors.default

  return (
    <div className={classnames(classes.socialLink, className)}>
      <a
        aria-label="Facebook"
        href="https://www.facebook.com/southpawstudionz/"
        target="_blank"
        rel="noreferrer"
        className={classes.socialLinkLink}
      >
        <FacebookLogo color={colorSet} />
      </a>
      <a
        aria-label="Instagram"
        href="https://www.instagram.com/southpawstudionz/?hl=en"
        target="_blank"
        rel="noreferrer"
        className={classes.socialLinkLink}
      >
        <InstagramLogo color={colorSet} />
        {addInstaTitle && (
          <span className={classes.socialLinkTitle}>southpawstudionz</span>
        )}
      </a>
    </div>
  )
}

SocialLink.propTypes = {
  isWhite: PropTypes.bool,
  className: PropTypes.string,
  addInstaTitle: PropTypes.bool,
}

SocialLink.defaultProps = {
  isWhite: false,
  addInstaTitle: false,
}

export default SocialLink
