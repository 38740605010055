import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import CartIconSvg from '~/images/cart-icon.svg'
import * as classes from './cartIcon.module.scss'

const CartIcon = ({ qty }) => {
  return (
    <Link
      to="/cart"
      className={classes.cartContainer}
      aria-label={`Shopping Cart with ${qty} items`}
    >
      {qty !== 0 && <div className={classes.cartQty}>{qty}</div>}
      <div className={classes.cartIcon}>
        <img src={CartIconSvg} alt="Cart" />
      </div>
    </Link>
  )
}

CartIcon.propTypes = {
  hasItems: PropTypes.bool,
  qty: PropTypes.number,
}

export default CartIcon
