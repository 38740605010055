import React from 'react'

const FacebookLogo = ({color}) => {
    return (
        <svg width="10" height="21" viewBox="0 0 10 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.21018 10.4851H6.46451V20.3485H2.2985V10.4851H0.317627V7.01734H2.2985V4.77376C2.2985 3.16788 3.07755 0.654327 6.49301 0.654327H9.58071V4.03366H7.34807C7.22451 4.02726 7.10103 4.04751 6.98633 4.09297C6.87164 4.13844 6.76852 4.20802 6.68426 4.2968C6.6 4.38558 6.53665 4.49139 6.49868 4.60678C6.46071 4.72217 6.44905 4.84432 6.46451 4.9646V7.00337H9.57596L9.21018 10.4851Z" fill={color}/>
        </svg>
    )
}

export default FacebookLogo
