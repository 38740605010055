import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classenames from 'classnames'
import Logo from '~/components/Logo'
import CartIcon from '~/components/CartIcon'
import SocialLink from '~/components/SocialLink'
import Menu from '~/components/Header/Menu'
import MenuMobile from '~/components/Header/MenuMobile'
import { StoreContext } from '~/context/StoreContext'

import * as classes from './header.module.scss'

const Header = () => {
  const { checkout } = useContext(StoreContext)

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  const {
    prismicNavigation: {
      data: { top_navigation: menuItems },
    },
    prismicBanner: {
      data: {
        announcement_active: isBannerActive,
        announcement: { text: bannerText },
      },
    },
  } = useStaticQuery(graphql`
    {
      prismicNavigation {
        data {
          title {
            text
          }
          top_navigation {
            link_label {
              text
            }
            link {
              uid
              isBroken
              target
            }
          }
        }
      }
      prismicBanner {
        data {
          announcement_active
          announcement {
            text
          }
        }
      }
    }
  `)

  return (
    <>
      {isBannerActive && bannerText && (
        <div className={classes.banner} role={'banner'}>
          {bannerText}
        </div>
      )}
      <header
        className={classenames('padding-5', classes.header)}
        style={{ display: 'flex' }}
      >
        <Logo isAnimation={true} />
        <div className={classes.leftHeader}>
          {menuItems && <Menu items={menuItems} />}
          <CartIcon qty={quantity} />
          {/* <Toast show={loading || didJustAddToCart}>
          {!didJustAddToCart ? (
            'Adding to cart…'
          ) : (
            <>
              Added to cart{' '}
              <svg
                width="14"
                height="14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
                  fill="#00295b"
                />
                <path
                  d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
                  fill="#00295b"
                />
                <path
                  d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
                  fill="#00295b"
                />
              </svg>
            </>
          )}
        </Toast> */}
          <SocialLink
            isWhite={false}
            addInstaTitle={false}
            className={classes.socialLinkHeader}
          />
          {menuItems && <MenuMobile items={menuItems} />}
        </div>
      </header>
    </>
  )
}

export default Header
