import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Lottie from 'lottie-react'

import southpawStudioLogo from '~/images/southpaw-studio-logo.svg'
import southpawStudioLogoWhite from '~/images/southpaw-studio-logo-white.svg'
import logoAnimatiom from '~/animation/logo.json'

import * as classes from './logo.module.scss'

const Logo = ({ alt, isWhite, isAnimation }) => {
  return (
    <Link to="/">
      {isAnimation ? (
        <Lottie
          animationData={logoAnimatiom}
          loop={false}
          className={classes.logoAnimated}
        />
      ) : (
        <img
          className={classes.logo}
          src={isWhite ? southpawStudioLogoWhite : southpawStudioLogo}
          alt={alt}
        />
      )}
    </Link>
  )
}

Logo.propTypes = {
  alt: PropTypes.string,
  isWhite: PropTypes.bool,
  isAnimation: PropTypes.bool,
}

Logo.defaultProps = {
  alt: 'Logo Southpaw studio',
  isWhite: false,
  isAnimation: false,
}

export default Logo
