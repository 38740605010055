import React from 'react'
import { Link } from 'gatsby'

import * as classes from './Menu.module.scss'

const Menu = ({ items }) => {
  return (
    <nav className={classes.navigation}>
      {items.map((item, index) => (
        <Link
          key={item.link_label.text}
          to={`/${item.link?.uid && item.link.uid}`}
        >
          {item.link_label.text}
        </Link>
      ))}
    </nav>
  )
}

export default Menu
