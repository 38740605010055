import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const Container = ({ className, children }) => {
  return (
    <div
      className={classnames(
        'padding-5',
        'width-100',
        'container-max',
        className
      )}
    >
      {children}
    </div>
  )
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Container
